<template>

<app-content-alert :theme="theme" class="event-ticketed">

	<template v-if="item.session.can.purchase">

		<app-icon icon="ticket" class="event-ticketed-icon" :text="cost" />
			
		<div class="event-ticketed-details" v-html="item.text.alert_onboard"></div>
			
		<app-button text="Buy ticket" theme="darkblue" class="event-ticketed-button" v-on:click="onConfirmClick" />

	</template>

	<template v-if="item.session.can.claim">

		<app-icon icon="ticket" class="event-ticketed-icon" :text="cost" />
			
		<div class="event-ticketed-details" v-html="item.text.alert_onboard"></div>

		<app-button text="Get ticket" theme="darkblue" class="event-ticketed-button" v-on:click="onConfirmClick" />

	</template>

	<template v-if="item.session.can.consume">

		<app-icon icon="ticket" class="event-ticketed-icon" :text="cost" />
			
		<div class="event-ticketed-details" v-html="item.text.alert_onboard"></div>

		<app-button text="Use ticket" theme="darkblue" class="event-ticketed-button" v-on:click="onConfirmClick" />

	</template>

	<template v-if="item.session.can.refund">

		<app-icon icon="tick" class="event-ticketed-icon" />
			
		<div class="event-ticketed-details" v-html="item.text.alert_seated"></div>

		<app-button text="Refund ticket" theme="darkgreen" class="event-ticketed-button" v-on:click="onConfirmClick" />

	</template>

	<template v-if="item.session.can.cancel">

		<app-icon icon="tick" class="event-ticketed-icon" />
			
		<div class="event-ticketed-details" v-html="item.text.alert_seated"></div>

		<app-button text="Cancel ticket" theme="darkgreen" class="event-ticketed-button" v-on:click="onConfirmClick" />

	</template>

	<template v-if="item.session.can.revoke">

		<app-icon icon="tick" class="event-ticketed-icon" />
			
		<div class="event-ticketed-details" v-html="item.text.alert_seated"></div>

		<app-button text="Don't use ticket" theme="darkgreen" class="event-ticketed-button" v-on:click="onConfirmClick" />

	</template>

	<template v-if="!canDoSomething && item.session.is.seated">

		<app-icon icon="tick" class="event-ticketed-icon" />
			
		<div class="event-ticketed-details" v-html="item.text.alert_seated"></div>

	</template>

	<template v-if="!canDoSomething && !item.session.is.seated">

		<app-icon icon="warning" class="event-ticketed-icon" />
			
		<div class="event-ticketed-details" v-html="item.text.alert_onboard"></div>

	</template>

</app-content-alert>

</template>

<script>

export default {

	props: ['item'],

	computed: {

		isFree: function() {

			return this.item.purchase.enabled === this.$constants.schedule.ticket.purchase.free

		},

		canDoSomething: function() {

			return this.item.session.can.purchase || this.item.session.can.refund || this.item.session.can.claim || this.item.session.can.cancel || this.item.session.can.consume || this.item.session.can.revoke 

		},

		cost: function() {

			return this.isFree ? 'Free' : '$'

		},

		theme: function() {

			return (this.item.session.is.seated) ? 'green' : 'blue'

		}

	},

	methods: {

		onConfirmClick: function() {

			this.$router.push({
				name: 'Convention.Schedule.Event.Ticket'
			})

		},

		onCancelClick: function() {

			this.$router.push({
				name: 'Convention.Schedule.Event.Ticket'
			})

		}

	}

}

</script>

<style scoped>

.event-ticketed {
	display: flex;
	align-items: center;
}

.is-mobile .event-ticketed {
	flex-direction: column;
	grid-column: 1/5;
}

.event-ticketed-icon {
	font-size: 48px;
	flex-shrink: 0;
	margin-right: 20px;
}

.is-mobile .event-ticketed-icon {
	margin-right: 0px;
}

.event-ticketed-details {
	flex-grow: 1;
	text-align: left;
}

.is-mobile .event-ticketed-details {
	padding: 20px 0px;
	text-align: center;
}

.event-ticketed-button {
	flex-shrink: 0;
	white-space: nowrap;
	margin-left: 20px;
	width: auto;
	min-width: 120px;
}

.is-mobile .event-ticketed-button {
	margin: 0px auto;
}

</style>